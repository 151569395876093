.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  z-index: 2;
  background-color: rgb(240, 240, 240, 0.8);
  backdrop-filter: blur(10px) !important;
}

.title {
  font-family: "Orbitron";
  font-size: 2rem;
  margin: 0;
  cursor: default;
}

.logo {
  width: 50px;
  height: 50px;
  margin-left: 5px;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
