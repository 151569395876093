.logo {
  width: 140px;
  height: 140px;
  margin-right: 40px;
  @media screen and (max-width: 610px) {
    margin-right: 0px;
    margin-top: -60px;
  }
}

.container {
  margin-top: 30px;
  width: 550px;
  max-width: 90vw;
}

.shoutContainer {
  justify-content: start;

  @media screen and (max-width: 610px) {
    flex-direction: column;
  }
}

.shout {
  font-size: 60px;
  white-space: nowrap;
  margin: 0;
}

.leftSpace {
  margin-left: 12px;
}

.pink {
  color: #ff17ae;
}

.main {
  position: relative;
  right: 5px;
  margin: 0;
  font-size: 40px;
  margin-top: 30px;
  opacity: 0;
  transition: 0.8s all ease;
}

.visible {
  right: 0px;
  opacity: 1;
}
