.title {
  font-family: "Orbitron";
  font-size: 2.8rem;
  margin: 0;
  cursor: default;
}

.logo {
  width: 80px;
  height: 80px;
}

.hook {
  cursor: default;
  margin: 0;
  font-family: "Monomaniac One";
  font-size: 1.5rem;
  text-align: left;
  width: 100%;
}

.hookContainer {
  position: relative;
  align-items: start;
  top: -10px;
  min-height: 4.5rem;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
