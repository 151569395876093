.userMessageContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  padding-right: 16px;
  padding-left: 16px;
}

.userMessage {
  padding: 12px;
  background-color: #171717;
  color: white;
  border-radius: 10px 10px 0px 10px;
  box-shadow: 5px 5px 0px 0px rgb(111, 111, 111);
  max-width: calc(100% - 30px);
  cursor: default;
}

.userMessageContent {
  padding: 0px;
  margin: 0px;
  white-space: pre-line;
  overflow-wrap: break-word;
  font-family: "Outfit";
}

.serviceMessageContainer {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 8px;
  padding-right: 16px;
  padding-left: 16px;
}

.serviceMessage {
  left: 0px;
  position: relative;
  padding: 12px;
  background-color: white;
  border-radius: 10px 10px 10px 0px;
  box-shadow: 5px 5px 0px 0px #ff17ae;
  max-width: calc(100% - 30px);
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    box-shadow: 7px 7px 0px 0px #ff17ae;
    left: 5px;
  }

  & pre {
    margin: 0;
    padding: 0;
  }
}

.serviceMessageContent {
  padding: 0px;
  margin: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-width: 100%;
  font-family: "Roboto Mono";
  font-size: 13px;
  font-weight: 450;
  letter-spacing: -0.5px;
  border-radius: 10px;
}
