.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.spaced {
  justify-content: space-between;
}

.popupContainer {
  width: calc(100% - 16px);
  height: calc(100% - 32px);
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.button {
  position: relative;
  right: 0px;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: "Outfit";
  font-weight: bold;
  height: 36px;
  border-radius: 100px;
  width: 100%;
  outline: none;
  border: 3px solid black;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0;
  transition: 0.2s all ease;
  box-sizing: border-box;
  &:not(.disabled):not(.loading):hover {
    right: 3px;
    box-shadow: 4px 4px 0px 0px black;
  }

  &.disabled,
  &.loading {
    opacity: 0.6;
    cursor: default;
  }

  & svg {
    height: 16px;
    width: 16px;
    position: absolute;
    right: 15px;
  }
}

.pink {
  background-color: #ff17ae;
}

.captureLayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
