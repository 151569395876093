.headerSpacer {
  height: 90px;
}

.container {
  font-family: "Monomaniac One";
  text-transform: uppercase;
  cursor: default;
}

.sectionHeader {
  font-size: 60px;
  margin-bottom: 0px;
}

.contact {
  text-transform: none;
  font-size: 24px;

  & a {
    color: #ff17ae;
    text-decoration: underline;
  }
}

.footerSpacer {
  height: 50px;
}

.free :global {
  -webkit-animation: pulsePinkColor 1.2s infinite linear;
}
