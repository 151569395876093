.anchor {
  position: relative;
  opacity: 0;
  right: 5px;
  transition: all 1s ease;
  margin-top: 40px;
  margin-bottom: 150px;
}

.button {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  position: relative;
  right: 0px;
  text-transform: none;
  color: white;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  background-color: #ff17ae;
  border: 3px solid black;
  transition: all 0.4s ease;
  box-shadow: 3px 3px 0px 0px black;
  font-size: 24px;

  & svg {
    height: 40px;
    width: 40px;
  }

  &:hover {
    right: 5px;
    box-shadow: 8px 8px 0px 0px black;
  }
}

.visible {
  right: 0px;
  opacity: 1;
}
