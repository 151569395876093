.userEntryRow {
  display: flex;
  justify-content: flex-end;
}

.userEntryContainer {
  width: 330px;
  height: 36px;
  position: relative;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  margin-left: 16px;
  margin-top: 0px;
  border: 3px solid black;
  border-radius: 26px;
  overflow: hidden;

  transition: all 0.2s ease;
  &:has(> :focus) {
    width: 100%;
    height: 80px;
  }

  &:hover,
  &:has(> :focus) {
    right: 4px;
    box-shadow: 4px 4px 0px 0px black;
  }
}

.userEntry {
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  padding-left: 12px;
  font-family: "Roboto Mono";
  font-weight: 600;
  border: 0;
  border-right: 3px solid black;
  box-sizing: border-box;

  color: black;
  background-color: white;
  outline: none;
  transition: all 0.2s ease;

  &::placeholder {
    color: rgb(186, 186, 186);
  }
}

.disabledUserEntry {
  opacity: 0.3;
  pointer-events: none;
}

.userEntrySend {
  height: 100%;
  width: 30px;
  border: 0;
  background-color: rgb(111, 111, 111);
  box-sizing: content-box;
  & svg {
    position: relative;
    right: 1px;
    color: white;
  }
  &:hover {
    cursor: pointer;
    background-color: rgb(28, 28, 28);
    & svg {
      color: white;
    }
  }
}
