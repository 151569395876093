.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(240, 240, 240, 0.8);
  backdrop-filter: blur(10px) !important;
}

.title {
  font-family: "Orbitron";
  font-size: 2.5rem;
  margin: 0;
  cursor: default;
}

.logo {
  width: 60px;
  height: 60px;
  margin-left: 5px;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.anchorLinkContainer {
  position: relative;
  margin-bottom: 10px;
  @media screen and (max-width: 610px) {
    display: none;
  }
}

.anchorLink,
.freeCallout {
  font-family: inherit;
  text-transform: inherit;
  padding-left: 15px;
  padding-right: 15px;
  background-color: transparent;
  color: black;
  font-size: 24px;
  transition: all 0.1s ease;
}

.anchorLink {
  display: flex;
  align-items: center;
  border-radius: 10px;
  outline: 0;
  border: 0;
  cursor: pointer;
  & span {
    position: relative;
    bottom: 2px;
  }
  &:hover {
    color: white;
    background-color: #ff17ae;
  }
}

.freeCallout :global {
  font-size: 16px;
  position: absolute;
  top: -16px;
  right: -78px;
  font-family: "Monomaniac One";
  transform: rotate(-20deg);
  -webkit-animation: pulsePinkColor 1.2s infinite linear;
}
