.container {
  width: 550px;
  max-width: 90vw;
  font-size: 24px;
  text-transform: none;

  & li {
    font-size: 18px;
  }

  & p {
    margin: 0;
  }

  & span {
    font-size: 24px;
  }
}

.parsedSites,
.priceContainer {
  opacity: 0;
  position: relative;
  right: 5px;
  transition: all 1s ease;

  & ul {
    padding-left: 10px;
  }
}

.visible {
  opacity: 1;
  right: 0;
}

.pricesContainer {
  font-size: 30px;
  margin-top: 40px;

  @media screen and (max-width: 610px) {
    flex-direction: column;
  }
}

.price {
  text-transform: uppercase;
  background-color: rgb(60, 60, 60);
  color: white;
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 10px;
  font-size: 36px;
  & p {
    position: relative;
    top: -3px;
    margin: 0;
  }
}

.parsedSites {
  font-size: 24px;
  & ul {
    margin-top: 10px;
    padding-left: 80px;
    list-style-type: none;
  }
}
