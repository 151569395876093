a {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.title {
  font-family: "Orbitron";
  font-size: 3rem;
  margin: 0;
  cursor: default;
}

.hook {
  cursor: default;
  margin: 0;
  font-family: "Monomaniac One";
  font-size: 1.5rem;
}

.logo {
  width: 80px;
  height: 80px;
}
