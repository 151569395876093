.actionPromptContainer {
  max-width: 100%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.actionPrompt {
  position: relative;
  right: 0px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  border-radius: 24px;
  border: 3px solid black;
  padding: 8px;
  padding-left: 16px;
  padding-right: 12px;
  cursor: pointer;

  transition: all 0.2s ease;

  &:hover {
    right: 4px;
    box-shadow: 4px 4px 0px 0px black !important;
    & svg,
    & p {
      color: black;
    }
  }

  & p {
    font-family: "Outfit";
    padding: 0;
    margin: 0;
    height: 100%;
    color: #444444;
  }

  & svg {
    color: #444444;
    height: 15px;
    width: 15px;
  }

  &.actionPromptDisabled {
    color: #cccccc;
    background-color: #dddddd;
    opacity: 0.3;
    min-width: 300px;
    pointer-events: none;
  }
}
