@keyframes typingAnimation {
  0% {
    -webkit-transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-5px);
  }
  44% {
    -webkit-transform: translateY(0px);
  }
}

@keyframes pulsePinkOuter {
  0%,
  100% {
  }
  50% {
    border: 3px solid #ff17ae;
    box-shadow: 4px 4px 0px 0px #ff17ae;
  }
}

@keyframes pulsePinkColor {
  0%,
  100% {
  }
  50% {
    color: #ff17ae;
  }
}

@keyframes pulsePinkBackground {
  0%,
  100% {
  }
  50% {
    background-color: #ff17ae;
  }
}

@keyframes pulseBlackColor {
  0%,
  100% {
  }
  50% {
    color: black;
  }
}
