.headerSpacer,
.footerSpacer {
  position: relative;
  height: 70px;
}

.headerSpacer p,
.footerSpacer p {
  position: absolute;
  bottom: 0px;
  font-family: "Outfit";
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #aaaaaa;
}

.footerSpacer p {
  bottom: 50px;
}

.endSessionContainer {
  z-index: 1;
  font-family: "Outfit";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 10px;
  position: absolute;
  height: fit-content;
}

.endSession {
  background-color: rgb(200, 200, 200);
  border-color: rgb(220, 220, 220);
  color: white;
  flex-grow: 0;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: black;
    border-color: white;
  }
}

.confirmEnd,
.nevermindEnd {
  background-color: white;
  border-color: black;
  color: black;

  &:hover {
    background-color: black;
    color: white;
    border-color: white;
  }
}

.container {
  min-height: 100vh;
  position: relative;
}
