.languageText {
  font-family: "Outfit";
  margin-left: 8px;
}

.language {
  height: 30px;
  display: flex;
  min-width: 7rem;
  align-items: center;
  background-color: white;
  &.selectorOpen {
    &:hover {
      background-color: black;
      color: white;
    }
  }
  &:last-of-type {
    border-radius: 0px 0px 15px 15px;
  }
  &:first-of-type {
    border-radius: 15px 15px 0px 0px;
  }
  &:only-of-type {
    border-radius: 15px 15px 15px 15px;
  }
}

.empty {
  width: fit-content;
  & span {
    color: #949494;
    margin: 15px;
  }
}

.languageSelector {
  position: fixed;
  top: 6px;
  right: 10px;
  z-index: 2;
  background-color: white;
  border: 3px solid rgb(0, 0, 0);
  transition: all 0.2s ease;
  border-radius: 20px;
  cursor: pointer !important;
  &:hover,
  &.isOpen {
    right: 12px;
    box-shadow: 4px 4px 0px 0px black;
  }
}

.languageNotSelected :global {
  box-shadow: 4px 4px 0px 0px black;
  -webkit-animation: pulsePinkOuter 1.2s infinite linear;
}

.languageNotSelectedCapture {
  background-color: rgb(240, 240, 240, 0.8);
  backdrop-filter: blur(10px) !important;
  z-index: 1;
}

.pulsePinkColor :global {
  -webkit-animation: pulsePinkColor 1.2s infinite linear;
}

.guideContainer {
  position: absolute;
  margin: 40px;
  overflow: scroll;
  max-height: calc(100vh - 60px);
}

.bigText,
.smallText {
  font-weight: 400;
  font-family: "Monomaniac One";
  color: black;
}

.bigText {
  margin: 0;
  margin-top: 40px;
  font-size: 25px;
  text-transform: uppercase;
}

.smallText {
  margin: 0;
  margin-top: 5px;
  font-size: 21px;
}
