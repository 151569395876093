.serviceMessageContainer {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 8px;
  padding-right: 16px;
  padding-left: 16px;
}

.serviceMessage {
  left: 0px;
  position: relative;
  padding: 12px;
  background-color: white;
  border-radius: 10px 10px 10px 0px;
  box-shadow: 5px 5px 0px 0px #ff17ae;
  max-width: calc(100% - 30px);
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    box-shadow: 7px 7px 0px 0px #ff17ae;
    left: 5px;
  }
}

.typingDot :global {
  -webkit-animation: typingAnimation 1.2s infinite ease-in-out;
  position: relative;
  top: 2.5px;
  border-radius: 6px;
  height: 6px;
  width: 6px;
  display: inline-block;
  margin: 5px;
  background-color: rgb(74, 74, 74);

  &:nth-of-type(1) {
    -webkit-animation-delay: 300ms;
  }
  &:nth-of-type(2) {
    -webkit-animation-delay: 400ms;
  }
  &:nth-of-type(3) {
    -webkit-animation-delay: 500ms;
  }
}
