.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  max-width: 90vw;
  text-align: center;

  & img {
    border: 3px solid #ff17ae;
    box-shadow: 4px 4px 0px 0px #ff17ae;
    border-radius: 10px;
  }
}

.stepContainer {
  display: flex;
  position: relative;
  right: 5px;
  opacity: 0;
  transition: all 1s ease;
  margin-bottom: 40px;
  @media screen and (max-width: 610px) {
    flex-direction: column;
  }
}

.link {
  text-decoration: underline;
  color: #ff17ae;
}

.stepTitle {
  margin: 0;
  margin-top: 20px;
  font-size: 30px;
}

.stepDetails {
  font-size: 24px;
  margin: 0;
  text-transform: none;
}

.visible {
  right: 0px;
  opacity: 1;
}

.popupImg {
  height: 200px;
}

.sessionImg {
  height: 500px;
}

.half {
  max-width: 395px;
}
